import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

const CareerCard = ({ input, props }) => {
  const { position, location, date_closing, job_details, application_link } = input.data;
  const date = new Date(date_closing);
  //Hooks
  const [open, toggle] = useState(false);
  //--
  const modalstyles = {
    modal: {
      padding: '0px',
      width: '100%'
    }
  };

  return (
    <div>
      <div
        className="flex mt4 mb4 justify-left bg-dark-blue-tint items-center pointer"
        onClick={() => toggle(true)}
      >
        <div className="bg-dark-blue white tc flex flex-column justify-center h4 w3 w4-l lh-copy">
          <span className="f4 w-100">{date.toLocaleString('en-us', { month: 'short' })}</span>
          <span className="b f3 w-100">{date.getDate()}</span>
        </div>
        <div className="ml3 ml4-l">
          <h4 className="dark-blue lh-solid f5 mb2">{location.text}</h4>
          <h2 className="dark-blue lh-solid f3 mt0 g-medium">{position.text}</h2>
        </div>
      </div>
      <Modal open={open} onClose={() => toggle(false)} center styles={modalstyles}>
        <div className="w-100 pb4-l">
          <div className="pl4 pr4 pl5-l pr5-l mt4 mb4 mt5-l mb5-l">
            <h4 className="yellow f5 mb0">{location.text}</h4>
            <h2 className="dark-blue f2 mt2 mb3 g-medium">{position.text}</h2>
            <div className="dark-blue g-medium">
              Applications Close:{' '}
              {date.toLocaleString('en-us', { day: '2-digit', month: 'short', year: 'numeric' })}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: job_details.html }}
              className="f5 grey mw7 center lh-copy mb4 mt4"
            />
            {application_link.url && (
              <a
                href={application_link.url}
                target="_blank"
                className="link yellow button g-medium ttu f5 flex items-center tracked"
                rel="noopener noreferrer"
              >
                Apply Now
              </a>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CareerCard;
