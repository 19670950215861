import React from 'react';
import Img from 'gatsby-image';

const BioModal = ({input}) => (
  <div className="w-100 pb4-l">
    <div className="flex-l w-100 justify-between items-center  flex-row-reverse-l bg-tint-yellow-l">
      <Img fluid={input.data.bio_image.localFile.childImageSharp.fluid} className="w-100 w-40-l bg-tint-yellow" />
      <div className="w-100 w-50-l pl5-l pl4 pr4 mt4 mt0-l pr0-l ">
        <div className="f3 semi-bold f2-l">{input.data.name.text}</div>
        <div className="f6 f5-l semi-bold yellow mt2 lh-copy">{input.data.position.text}</div>
      </div>
    </div>
    <div className="pl4 pr4 pl5-l pr5-l mt4 mb4">
      <div dangerouslySetInnerHTML={{__html: input.data.bio.html}} className="f5 grey mw7 center lh-copy mb4" />
      <a href={input.data.email.url} className="link yellow button g-medium ttu f5 flex items-center tracked">
        Contact
      </a>
    </div>
  </div>
);
export default BioModal;
