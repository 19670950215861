import React from 'react';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Sticky from 'react-sticky-el';
import '../css/pageNav.css';

const PageNav = ({items}) => {
  const itemIds = items.map(a => a.id);
  return (
    <Sticky className="pt4 pb4 pl4 pr4 tc z-9999 bg-white page-nav dn db-ns">
      <Scrollspy items={itemIds} currentClassName="is-current" className="center tc list mt0 mb0">
        {items.map(menuItem => (
          <li className="dib ml4 mr4 f6 g-medium ttu">
            <AnchorLink href={`#${menuItem.id}`} className="link pl3 pr3 db" offset="120">
              {menuItem.primary.page_nav_title.text}
            </AnchorLink>
          </li>
        ))}
      </Scrollspy>
    </Sticky>
  );
};

export default PageNav;
