import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import noScroll from 'no-scroll';
import '../css/modal-form.css';
import MailIcon from './MailIcon';
import CloseIcon from './CloseIcon';
import ServiceForm from './ServiceForm';

const ModalForm = ({ formId, pageTitle }) => {
  const [isOpen, toggle] = useState(false);
  //--
  const { x, y } = useSpring({
    x: isOpen ? 1 : 0,
    y: isOpen ? 0 : 10,
    config: config.gentle
  });
  //--
  return (
    <div>
      <animated.button
        onClick={event => {
          toggle(true);
          noScroll.on();
        }}
        className={`pointer fixed right-0 right-2-l bottom-2 z-5 form-modal-enticer ${isOpen &&
          'modal-active'}`}
      >
        <MailIcon />
      </animated.button>
      <animated.div
        className="vw-100 vh-100 modal-form z-max"
        style={{
          opacity: x.interpolate(x => x),
          pointerEvents: isOpen ? 'all' : 'none'
        }}
      >
        <div
          className="w-100 vh-100 absolute top-0 left-0 db z-0 pointer"
          onClick={event => {
            toggle(false);
            setTimeout(function() {
              //Start the timer
              noScroll.off();
            }, 800);
          }}
        />
        <animated.div
          className="modal-inner w-100 mw7 center bg-tint-yellow relative z-max"
          style={{
            transform: y.interpolate(y => `translate3d(0,${y}%, 0)`)
          }}
        >
          <button
            onClick={event => {
              toggle(false);
              setTimeout(function() {
                //Start the timer
                noScroll.off();
              }, 800);
            }}
            className=" absolute right-1 top-1 pointer"
          >
            <CloseIcon />
          </button>
          <ServiceForm formId={formId} pageTitle={pageTitle} />
        </animated.div>
      </animated.div>
      >
    </div>
  );
};

export default ModalForm;
