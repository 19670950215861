import React from 'react';
import {Link as GatsbyLink} from 'gatsby';

const PrismicLink = ({children, to, type, activeClassName, ...other}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.

  // Use Gatsby Link for internal links, and <a> for others
  if (type === 'Document') {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default PrismicLink;
