import React from 'react';
import { navigate } from 'gatsby-link';
import '../css/forms.css';
import Fade from 'react-reveal/Fade';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <div className="w-100 pl4 pr4 bg-tint-yellow pt5 pb5 pt6-l pb6-l">
        <Fade>
          <div className="f2 g-medium tc center mb5">Get in touch</div>
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            onSubmit={this.handleSubmit}
            className="mw6 center"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            <p>
              <input
                type="text"
                name="name"
                onChange={this.handleChange}
                className="w-100 input-reset pa3"
                placeholder="Full Name"
                required="true"
              />
            </p>
            <p>
              <input
                type="email"
                name="email"
                onChange={this.handleChange}
                className="w-100 input-reset pa3"
                placeholder="Email Address"
                required="true"
              />
            </p>
            {/* <p className="select-wrapper relative">
              <select className="w-100 pa3 input-reset bg-white">
                <option disabled selected value>
                  Select Category
                </option>
                <option value="General Enquiry">General Enquiry</option>
                <option value="Commerical">Commercial</option>
                <option value="Retail">Retail</option>
                <option value="Apiculture">Apiculture</option>
                <option value="Organic">Organic</option>
              </select>
            </p> */}
            <p>
              <textarea
                name="message"
                onChange={this.handleChange}
                className="w-100 input-reset pa3 h4"
                placeholder="Your Message"
              />
            </p>
            <p>
              <button
                type="submit"
                className="link black button g-medium ttu f5 tracked flex items-center center mt4"
              >
                Submit
              </button>
            </p>
            <div data-netlify-recaptcha="true" />
          </form>
        </Fade>
      </div>
    );
  }
}
