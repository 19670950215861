import React from 'react';
import PropTypes from 'prop-types';
import '../css/slick.css';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';

class BasicGallery extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    const {items, id} = this.props.input;
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            dots: true,
            arrows: false
          }
        }
      ]
    };
    return (
      <Fade>
        <div id={id} className="gallery-wrapper center pl4 pr4 gallery mw8 relative mb5 mb0-l">
          <Slider {...settings} className="relative">
            {items.map((slide, index) => (
              <div>
                {slide.image.localFile && <Img fluid={slide.image.localFile.childImageSharp.fluid} />}
                <div className="flex items-center mt3">
                  <div className="f5 semi-bold mr2">
                    {index + 1} of {items.length}
                  </div>
                  <div className="f5 grey">{slide.image_caption.text}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Fade>
    );
  }
}

export default BasicGallery;

BasicGallery.propTypes = {
  input: PropTypes.object.isRequired
};
