import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import CareerCard from '../components/CareerCard';

const CareersFeed = ({ input }) => {
  const { title, text } = input.primary;
  return (
    <div className="pl4 pr4 center mw8 mt5-l mb5-l">
      <h3 className="f3 f-intro-l yellow measure center lh-copy tc-l">{title.text}</h3>
      <div
        className="measure-widest center lh-copy f5 tl grey mt1 mw8 tc-l"
        dangerouslySetInnerHTML={{ __html: text.html }}
      />
      <StaticQuery
        query={graphql`
          query {
            allPrismicJobVacancy(sort: { fields: [data___date_closing], order: ASC }) {
              edges {
                node {
                  data {
                    position {
                      text
                    }
                    location {
                      text
                    }
                    job_details {
                      html
                    }
                    application_link {
                      url
                    }
                    date_closing
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="job-feed mt4 mb4 mt5-l mb6-l">
            {data.allPrismicJobVacancy.edges.map(item => (
              <CareerCard input={item.node} />
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default CareersFeed;
