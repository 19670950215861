import React from 'react';

const IntroText = ({ input }) => (
  <div id={input.id} className="mt4 mb4 mt6-l mb6-l pl4 pr4 tl tc-l">
    {input.primary.title.text && <h3 className="yellow f5 semi-bold ttu">{input.primary.title.text}</h3>}
    <div className="f3 f-intro-l black measure center lh-copy">{input.primary.quote.text}</div>
    {input.primary.text.html && <div className="measure-widest center two-col lh-copy f5 tl grey mt5-l mw8" dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />}
  </div>
);

export default IntroText;
