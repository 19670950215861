import React from 'react';
import Img from 'gatsby-image';
import PrismicLink from '../components/PrismicLink';
import Fade from 'react-reveal/Fade';

const ImageText = ({input}) => (
  <div id={input.id} className={`mt5 mb5 mt6-l mb6-l pl4 pr4 image-text ${input.primary.full_width === 'on' ? 'pl0-l pr0-l' : 'pl5-l pr5-l'} flex-l items-center justify-between mw9 center img-${input.primary.image_position}`}>
    {input.primary.image.localFile && <Img fluid={input.primary.image.localFile.childImageSharp.fluid} className="w-100 h-auto w-50-l" />}
    <Fade>
      <div className={`w-100 w-40-l pl4-l pr4-l full-width-${input.primary.full_width}`}>
        <h2 className="f3 f2-l g-medium lh-copy measure">{input.primary.title.text}</h2>
        <div dangerouslySetInnerHTML={{__html: input.primary.text.html}} className="f5 lh-copy grey" />
        {input.primary.link && (
          <PrismicLink to={input.primary.link.url} type={input.primary.link.link_type} className="link buttonlink yellow button semi-bold ttu f6 flex items-center tracked mt4">
            {input.primary.link_text.text}
          </PrismicLink>
        )}
      </div>
    </Fade>
  </div>
);

export default ImageText;
