import React from 'react';
import MapContainer from '../components/MapContainer';
import Fade from 'react-reveal/Fade';

const ContactBlock = ({input}) => (
  <Fade>
    <div className="center pl4 pr4 pl5-l pr5-l gallery mw9 flex-l mt6-l mb6-l justify-between items-center mb5">
      <div className="w-100 w-50-l relative contact-map">
        <MapContainer location={input.primary.location} />
      </div>
      <div className="w-100 w-40-l pl4-l pr4-l mt4 mt0-l">
        <div className="yellow f5 semi-bold ttu">{input.primary.sub_title.text}</div>
        <div className="f3 f-intro-l mt2 mb2 mt4-l mb4-l">{input.primary.title.text}</div>
        <div dangerouslySetInnerHTML={{__html: input.primary.contact_details.html}} className="f5 lh-copy grey" />
      </div>
    </div>
  </Fade>
);

export default ContactBlock;
